import React, { useState, useContext } from "react";

import { DashboardLayout } from "../../fragments/Layout";

import { Container, Row } from "react-bootstrap";

import useFetchDesigns from "../../../hooks/useFetchAllDesigns";

import PaginateDes from "../../fragments/PaginateDes";
import SubAllDesigns from "./SubAllDesigns";

// redirects
import { Redirect } from "react-router-dom";
// authentication
import { AuthContext } from "../../contexts/AuthContext";

import Loader from "../../../fragments/Loader";

const AllDesigns = () => {
  const { designs, loading } = useFetchDesigns();

  //use context to get sign in status
  const { loadinggg, loggedIn } = useContext(AuthContext);

  //implementing pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [designsPerPage] = useState(9);

  //get current designs
  const indexOfLastDesign = currentPage * designsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;
  const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign);

  //change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // authentication redirect
  // authentication redirect

  if (loadinggg === true && loggedIn === false) {
    return <Loader />;
  }

  if (loadinggg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  return (
    <DashboardLayout>
      <Container className="mt-12 enlarge">
        <Row className="mb-4">
          <h2 className="ml-3 h3-fix">Building Designs</h2>
        </Row>

        <SubAllDesigns designs={currentDesigns} loading={loading} />

        <Row className="mt-4">
          <PaginateDes
            paginate={paginate}
            designsPerPage={designsPerPage}
            totalDesigns={designs.length}
          />
        </Row>
      </Container>
    </DashboardLayout>
  );
};

export default AllDesigns;
