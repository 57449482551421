import { useReducer, useEffect } from "react";

//importing firebase and initializing db constant
import { firestore } from "../firebase";

const ACTIONS = {
  MAKE_REQUEST: "make-request",
  GET_DATA: "get-data",
  ERROR: "error",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.MAKE_REQUEST:
      return { loading: true, clients: [] };

    case ACTIONS.GET_DATA:
      return { ...state, loading: false, clients: action.payload.clients };

    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        clients: [],
      };

    default:
      return state;
  }
}

export default function useFetchClients(params) {
  const [state, dispatch] = useReducer(reducer, { clients: [], loading: true });

  useEffect(() => {
    //retrieving all the clients
    let allclients = [];
    dispatch({ type: ACTIONS.MAKE_REQUEST });
    async function getAllclients() {
      const clients = firestore.collection("clients");
      // .where("startdate", ">=", params)
      // .orderBy("startdate", "asc");

      const snapshot = await clients.get();
      if (snapshot.empty) {
        return;
      }

      snapshot.forEach((doc) => {
        allclients.push(doc.data());
        dispatch({
          type: ACTIONS.GET_DATA,
          payload: { clients: allclients },
        });
      });
    }

    getAllclients();
  }, [params]);

  return state;
}
