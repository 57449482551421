import React, { useState, useContext } from "react";

import { DashboardLayout } from "../../fragments/Layout";

import { Container, Row } from "react-bootstrap";

import useFetchClients from "../../../hooks/useFetchClients";
import Pagination from "../../fragments/Pagination";

import SubAllClients from "./SubAllClients";

// redirects
import { Redirect } from "react-router-dom";
// authentication
import { AuthContext } from "../../contexts/AuthContext";

import Loader from "../../../fragments/Loader";

const Clients = () => {
  // authentication to view page
  //use context to get sign in status
  //use context to get sign in status
  const { loadinggg, loggedIn } = useContext(AuthContext);

  const { clients, loading } = useFetchClients();

  //pagination
  //implementing pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(10);

  //get current clients
  const indexOfLastDesign = currentPage * clientsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - clientsPerPage;
  const currentClients = clients.slice(indexOfFirstDesign, indexOfLastDesign);

  //change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // authentication redirect

  if (loadinggg === true && loggedIn === false) {
    return <Loader />;
  }

  if (loadinggg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  return (
    <DashboardLayout>
      <Container className="mt-12 enlarge">
        <Row className="mb-4">
          <h2 className="ml-3 h2-fix mb-4">Clients</h2>
        </Row>

        <SubAllClients clients={currentClients} loading={loading} />

        <Row className="mt-4 mb-4">
          <Pagination
            paginate={paginate}
            clientsPerPage={clientsPerPage}
            totalclients={clients.length}
          />
        </Row>
      </Container>
    </DashboardLayout>
  );
};

export default Clients;
