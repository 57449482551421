import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDFHcZTyw0XPfpObL_-PPIy6ZkSAbMbYNg",
  authDomain: "plansandbills.firebaseapp.com",
  projectId: "plansandbills",
  storageBucket: "plansandbills.appspot.com",
  messagingSenderId: "782936671120",
  appId: "1:782936671120:web:0ae360d6a1d9bccf972915",
  measurementId: "G-F5W5GWZJSS",
};

export const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();

export const firestore = app.firestore();

const projectStorage = firebase.storage();

const storageRef = firebase.storage().ref();

export { projectStorage };

// REGISTER NEW CLIENT
export const register = (
  {
    name,
    email,
    phone,
    country,
    region,
    selectedBuilding,
    timeToBuild,
    plotOfLand,
    notes,
  },
  handlePro
) => {
  return firestore
    .collection("clients")
    .add({
      new: true,
      name,
      email,
      phone,
      country,
      region,
      selectedBuilding,
      timeToBuild,
      plotOfLand,
      notes,
      registeredAt: Math.round(Date.now() / 1000),
    })
    .then(() => {
      // window.location.reload(false);
      handlePro();
    })
    .catch((e) => {
      console.log("error:", e);
      return e;
    });
};

export const slider_upload = (data) => {
  console.log("inslider upload", data);
  return firestore
    .collection("sliders")
    .add({
      fileName: data.filename,
      downloadURL: data.downloadURL,
    })
    .then((docRef) => {
      firestore.collection("sliders").doc(docRef.id).set(
        {
          id: docRef.id,
        },
        { merge: true }
      );
      window.location.reload(false);
    });
};
// UPLOAD NEW BUILDING DESIGN
export const upload_design = ({
  storey,
  bedrooms,
  diningroom,
  buildingType,
  livingroom,
  washrooms,
  desc,
  entrances,
  area,
  downloadURLs,
  filenames,
}) => {
  return firestore
    .collection("designs")
    .add({
      buildingType: buildingType.value,
      storey,
      bedrooms,
      diningroom,
      livingroom,
      washrooms,
      desc,
      entrances,
      area,
      visible: true,
      gallery: downloadURLs,
      filenames: filenames,
      createdAt: Math.round(Date.now() / 1000),
    })
    .then((docRef) => {
      firestore.collection("designs").doc(docRef.id).set(
        {
          id: docRef.id,
        },
        { merge: true }
      );
      window.location.reload(false);
    })
    .catch((error) => {
      return error;
    });
};

// detete slider image
export const deleteSlider = (data) => {
  // delete photo in storage
  const oldFileRef = storageRef.child(`/sliders/${data.fileName}`);
  oldFileRef
    .delete()
    .then(() => {
      console.log("deelteing from firesotre");
      //  delete event from firestore
      firestore.collection("sliders").doc(data.id).delete();
      // window.location.reload(false);
    })
    .catch((err) => {
      console.log("error occured", err);
    });
};

// DELETE BUILDING DESIGN
export const delete_design = (id) => {
  return firestore
    .collection("designs")
    .doc(id)
    .delete()
    .then(() => window.location.reload(false));
};

// TOGGGLE VISIBILITY OF DESIGN
export const toggle_visibility = (data) => {
  console.log("data", data.toggle);
  return firestore.collection("designs").doc(data.id).update(
    {
      visible: data.toggle,
    },
    { merge: true }
  );
};

// UPLOAD NEW BUILDING DESIGN
export const edit_design = ({
  storey,
  bedrooms,
  diningroom,
  livingroom,
  washrooms,
  desc,
  entrances,
  area,
  id,
}) => {
  return firestore
    .collection("designs")
    .doc(id)
    .update({
      storey: storey,
      bedrooms: bedrooms,
      diningroom: diningroom,
      livingroom: livingroom,
      washrooms: washrooms,
      desc: desc,
      entrances: entrances,
      area: area,
    })
    .then((docRef) => {
      window.location.reload(false);
    })
    .catch((error) => {
      return error;
    });
};

// logout functionality implemented also in dashboard component
export const logout = () => {
  return auth.signOut();
};

// upload project
// UPLOAD NEW BUILDING DESIGN
export const upload_project = ({
  desc,
  title,
  downloadURLs,
  filenames,
  status,
}) => {
  console.log("reached here");
  return firestore
    .collection("projects")
    .add({
      desc,
      title,
      status: status.value,
      gallery: downloadURLs,
      filenames: filenames,
      createdAt: Math.round(Date.now() / 2000),
    })
    .then((docRef) => {
      firestore.collection("projects").doc(docRef.id).set(
        {
          id: docRef.id,
        },
        { merge: true }
      );
      window.location.reload(false);
    })
    .catch((error) => {
      return error;
    });
};
