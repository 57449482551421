import React from "react";

//bootstrap import
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

//image import
import Logo from "../img/planslogo.png";

export default function Footer() {
  var year = new Date();

  let actual = year.getFullYear();
  return (
    <div>
      <Container fluid className="footer">
        <Container>
          <Row className="mx-auto">
            {/* LOGO */}
            <Col className="footer-col" md={3}>
              <img src={Logo} width="100px" alt="Plans and bills" />
            </Col>
            {/* SUPPORT */}

            {/* CONTACT */}
            <Col className="footer-col" md={3}>
              <h4 className="text-white h3-fix">Contact</h4>
              <ul className="footer-list">
                <li>info@plansandbills.com</li>
                <li>+233 27 765 6523</li>
                <li>+233 54 406 3601</li>
              </ul>
            </Col>

            {/* ADDRESS */}
            <Col className="footer-col" md={3}>
              <h4 className="text-white h3-fix">Address</h4>
              <ul className="footer-list">
                <li>Plans and Bills,</li>
                <li>Clove Street</li>
                <li>South Odorkor Estates</li>
                <li>Accra, Ghana</li>
              </ul>
            </Col>
            {/* working hours */}
            <Col className="footer-col" md={3}>
              <h4 className="text-white h3-fix">Working Hours</h4>
              <ul className="footer-list">
                <li>Weekdays 8am to 5pm </li>
                <li>Saturdays 9am to 4pm</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="year-print">
        <Container>
          <Row>
            <Col md={6} className="year">
              © {actual} Plans and Bills
            </Col>
            <Col md={6}>
              <ul className="year">
                <Link to="/privacy-policy">
                  <li>Privacy Policy & Terms of Use</li>
                </Link>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
