import React, { useEffect } from "react";

import { Link } from "react-router-dom";

//Components import
import NavigationBar from "../components/Navbar";
import Footer from "../components/Footer";
import Featured from "../components/landing-fragments/Featured";
import WhyChoose from "../components/landing-fragments/WhyChoose";
import Ready from "../components/landing-fragments/Ready";
import Scripture from "../components/landing-fragments/Scripture";

//booststrap import
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";

import useFetchSliders from "../hooks/useFetchSliders";

//temporal image imports
import Home from "../img/home.jpeg";

export default function Landing() {
  const { sliders, loading } = useFetchSliders();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container fluid className="min-height-100">
        <NavigationBar theme="dark" bg="none" color="null" />
        <Row className="caro-pos">
          <Carousel
            className="header-carousel img-parent"
            controls={false}
            interval={2700}
            indicators={true}
            slide={false}
          >
            {loading && (
              <Carousel.Item>
                <img className="carousel-img" src={Home} alt="First slide" />

                <Carousel.Caption>
                  <Container>
                    <Row className="featured-image-div">
                      <Col sm={12} className="text-center">
                        <h1 className="featured-title-2">
                          Build your new home anytime at your convenience{" "}
                        </h1>
                        <Link
                          to={`/designs`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="light" className="mt-4">
                            Browse Designs
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
            )}
            {sliders.map((slide) => {
              return (
                <Carousel.Item className="img-parent">
                  <img
                    className="carousel-img"
                    src={slide.downloadURL}
                    alt="Event "
                  />

                  <Carousel.Caption className="carousel-deets text-center">
                    <Container>
                      <Row>
                        <Col sm={12} className="text-center">
                          <h1 className="featured-title">
                            {" "}
                            Build your new home anytime at your convenience{" "}
                          </h1>

                          <Link
                            to={`/designs`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button variant="light" className="mt-4">
                              Browse Designs
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Row>
      </Container>
      <Featured />
      <Scripture />
      <Ready />
      <WhyChoose />
      <Footer />
    </div>
  );
}
