import React from "react";

import { Link } from "react-router-dom";

//Importing react bootstrap
import { Card, Col } from "react-bootstrap";

export default function ProjectCard(props) {
  const params = props.params;

  //get first gallery image and  use that as card image
  //checking for undefined to avoid errors

  const featured = params.gallery;

  // Project Status
  let statuss = "On Going";
  if (params.status === "ongoing") {
    statuss = " On Going";
  }
  if (params.status === "completed") {
    statuss = "Completed";
  }

  // featured image
  let featuredImage = null;

  if (featured !== undefined) {
    featuredImage = featured[0];
  }
  return (
    <Col md={4} className="mb-4" id={params.area}>
      <Link
        to={`/projects/${params.createdAt}`}
        style={{ textDecoration: "none" }}
      >
        <Card className="design-card">
          <Card.Img
            className="design-card-img"
            variant="top"
            alt="Building Design"
            src={featuredImage}
          />
          <Card.Body>
            <Card.Title>
              <h5>{params.title}</h5>
            </Card.Title>
            <Card.Text className="design-card-text">
              Status: {statuss}
            </Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
}
