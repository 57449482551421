import React, { useEffect } from "react";

//bootstrap import
import { Container, Row, Col, Card } from "react-bootstrap";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

// Image imports
import home from "../img/homeconstruction2.jpg";
import renovation from "../img/renovation1.png";
import realestate from "../img/realestatedev1.jpeg";
import civilservices from "../img/civilservices.jpg";

export default function WhatWeDo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col md={12} className="text-white text-center purple-header">
            <h1 className="h1-fix">What We Do</h1>
          </Col>
        </Row>
        <Container className="mt-4 mb-4 ">
          <Card className="section-card">
            <Container className="inner-container">
              {/* INTRO TEXT */}
              <Row className="mb-4 pb-4">
                <Col md={11} className="mx-auto justify">
                  <p className="text-center p-size mb-4">
                    Plans and Bills is into a wide range of construction and
                    project management services. Our services include the
                    following:
                  </p>
                </Col>
              </Row>

              {/* FIRST ROW OF SERVICES */}
              <Row className="mt-4 pt-4 mb-4 pb-4">
                <Col mb={6} className="justify mb-4 px-4">
                  <img
                    src={home}
                    className="whatwedo-img"
                    alt="home construction"
                  />
                  <h3 className="text-center mb-4 h3-fix">Home Construction</h3>
                  <p>
                    Looking to build your next home? Let’s help you get started.
                    At Plans and Bills, we build homes to fit your taste. From
                    conception to construction and beyond, Plans and Bills is
                    ready to plan and work with you to build your dream home.
                    Visit our gallery of ultramodern home designs today.
                  </p>
                </Col>
                <Col mb={6} className="justify px-4">
                  <img
                    src={renovation}
                    className="whatwedo-img"
                    alt="renovation"
                  />
                  <h3 className="text-center mb-4 h3-fix">
                    Renovation and Office Fit-Out
                  </h3>
                  <p>
                    Do you want to add some value to your existing property? We
                    would help you reimage your building to suit new
                    requirements. From new tiling works for your home, fit out
                    works, to refurbishing of your office space, our
                    comprehensive experience makes us ideally suited.
                  </p>
                </Col>
              </Row>

              {/* SECOND ROW OF SERVICES */}
              <Row className="mt-4 pt-4 mb-4 pb-4">
                <Col mb={6} className="px-4">
                  <img
                    src={realestate}
                    className="whatwedo-img"
                    alt="home construction"
                  />
                  <h3 className="text-center mb-4 h3-fix">
                    Real Estate Services
                  </h3>
                  <p>
                    We start with a concept and end with a fully developed
                    property ready to be purchased or leased. Plans and Bills
                    provides services ranging from facilities management,
                    property valuation and appraisal to the strategic management
                    and development of commercial and residential property
                    assets.
                  </p>
                </Col>
                <Col className=" ml-4">
                  <img
                    src={civilservices}
                    className="whatwedo-img"
                    alt="home construction"
                  />
                  <h3 className="text-center mb-4 h3-fix">
                    Project Management Services
                  </h3>
                  <p>
                    Our Project Managers are ready and able to shoulder the
                    responsibility of keeping your project moving according to
                    plan by using modern management tools and techniques for
                    every phase of your construction project to achieve high
                    quality and value with the needs of our clients.
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
