import React, { useState, useEffect, createContext } from "react";

import { auth } from "../../firebase";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [authState, setAuthState] = useState({
    loadinggg: true,
    loggedIn: false,
    user: null,
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setAuthState({ loadinggg: false, loggedIn: Boolean(user), user: user });
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn: authState.loggedIn,
        loadinggg: authState.loadinggg,
        user: authState.user,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
