import React from "react";

import { Container, Row, Col } from "react-bootstrap";

//icons import
import { FaPencilAlt, FaMoneyCheckAlt } from "react-icons/fa";

import { GiTeamIdea } from "react-icons/gi";

export default function Featured() {
  return (
    <div>
      <Container className="sub-section">
        <Row>
          <Col md={6} className="mx-auto mb-4 text-center px-4">
            <h2 className="h2-fix mb-2">Why Choose Plans and Bills?</h2>
            <hr width="300" className="text-center mx-auto" />
          </Col>
        </Row>
        <Row>
          <Col md={4} className="text-center px-4">
            <div className="choose-rounded">
              <FaPencilAlt size={70} className="icon-style" />
            </div>

            <h4 className="mt-3 mb-3 choose-headings">Ultramodern Designs</h4>
            <p className="justify">
              We provide you with limitless ready-made and custom building
              designs. Visit our gallery of ultramodern designs today for your
              next project.
            </p>
          </Col>
          <Col md={4} className="text-center px-4">
            <div className="choose-rounded">
              <FaMoneyCheckAlt size={70} className="icon-style" />
            </div>

            <h4 className="mt-3 mb-3 choose-headings">Stay on Budget</h4>
            <p className="justify">
              We bring both the design and construction phases of your project
              under the same roof which offers faster delivery and cost
              efficiency of your project
            </p>
          </Col>
          <Col md={4} className="text-center px-4">
            <div className="choose-rounded">
              <GiTeamIdea size={70} className="icon-style" />
            </div>

            <h4 className="mt-3 mb-3 choose-headings">Quality Service</h4>
            <p className="justify">
              Our project team is highly experienced and employs eco-friendly
              construction methods with the use of quality building materials
              for all projects.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
