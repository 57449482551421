/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { FiMenu } from "react-icons/fi";

//Bootstrap imports
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

//image import
import Logo from "../img/planslogo.png";

export default function NavigationBar(props) {
  const [bg, setBg] = useState("transparent");
  const [variant, setVariant] = useState("dark");

  const listenScrollEvent = (e) => {
    if (window.scrollY > 80) {
      setBg("light");
      setVariant("light");
    } else {
      setBg("transparent");
      setVariant("dark");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });

  return (
    <Container fluid>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        variant={variant}
        bg={bg}
        className="px-4"
      >
        <Container>
          <Navbar.Brand className="link-reset">
            <Link to="/">
              <img src={Logo} width="40px" alt="Plans and Bills" />
            </Link>
            <span className="pl-3 log-options">Plans and Bills</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FiMenu />
          </Navbar.Toggle>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-nav ml-auto mt-2 mt-lg-0">
              <Link to="/" className="main-nav-link">
                <Nav.Link href="/">Home</Nav.Link>
              </Link>
              <NavDropdown title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item href="/what-we-do">
                  What We Do
                </NavDropdown.Item>
                <NavDropdown.Item href="/our-social-responsibilities">
                  Our Social Responsibilities
                </NavDropdown.Item>
              </NavDropdown>

              <Link to="/designs" className="main-nav-link">
                <Nav.Link href="/designs">Browse Designs</Nav.Link>
              </Link>
              <Link to="/projects" className="main-nav-link">
                <Nav.Link href="/projects">Projects</Nav.Link>
              </Link>

              <NavDropdown title="About Us" id="basic-nav-dropdown">
                <NavDropdown.Item href="/who-we-are">
                  Who We Are
                </NavDropdown.Item>
                <NavDropdown.Item href="/how-we-work">
                  How We Work
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}
