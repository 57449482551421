import React, { useEffect } from "react";

//bootstrap import
import { Container, Row, Col } from "react-bootstrap";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

export default function Privacypolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col md={12} className="text-white text-center purple-header">
            <h3 className="h2-fix">Privacy Policy</h3>
          </Col>
        </Row>
        <Container className="mt-4 mb-4">
          <Row>
            <Col md={12}>
              <p>
                Plans and Bills takes privacy seriously. Below are the terms and
                practices regarding www.plansandbills.com. Please note this
                privacy notice is strictly about information collected on this
                website. <br />
                <br />
                Information Collection & Use
                <br /> Plans and Bills is the sole owner of the information
                collected on this website. We only have access to information
                that you voluntarily submit via email or purchase form. Before
                submitting or uploading any information, please note that we
                will use your information to respond to you regarding your
                enquiry. We will not share your information with any third party
                outside of our organization. Unless you specifically subscribe
                to our newsletter using the clearly labeled subscription form,
                we will not contact you in the future for any reason other than
                the nature of your inquiry.
                <br />
                <br /> Information Access & Control
                <br /> You may contact us at any time via email or phone to
                inquire about your information, including:  Learn what data we
                have about you, if any;  Update any data we have about you; 
                Request your data be deleted;  Relay a concern you have about
                our use of your data. <br /> <br />
                Security <br /> As any organization should, we take precautions
                to protect your data. When you submit or upload information via
                this website, your information is protected online using a
                password-protected database that is monitored by security
                software, as well as an SSL Certificate. You can verify this by
                looking for “https” at the beginning of the URL of any page of
                this website. While we use encryption to protect information
                transmitted digitally, we also protect your information offline.
                Only our employees who need your information to perform a
                specific function (e.g. contacting you about an enquiry) are
                granted access to personally identifiable information. The
                computers and servers used to store your personally identifiable
                information are maintained in a secure environment.
                <br />
                <br /> Links <br />
                From time to time, our website may contain links to other
                websites. Please note that we are not responsible for the
                content or privacy practices of any website apart from
                www.plansandbills.com and, as such, we encourage you to exercise
                caution and awareness when using these websites.
                <br />
                <br /> Cookies
                <br /> From time to time, our website may contain links to other
                websites. Please note that we are not responsible for the
                content or privacy practices of any website apart from
                wwwplansandbills.com and, as such, we encourage you to exercise
                caution and awareness when using these websites. If you have any
                questions or concerns about this privacy policy, and/or your
                personal information, please contact us at + 233 27 765 6523 / +
                233 54 406 3601 or via email at info@plansandbills.com.
              </p>
            </Col>
          </Row>
        </Container>
        {/* TEAM MEMBERS */}
      </Container>
      <Footer />
    </>
  );
}
