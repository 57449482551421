import React from "react";

import { Container, Row, Col } from "react-bootstrap";

//import fetch design hook
import useFetchDesigns from "../../hooks/useFetchDesigns";

//impprt components
//import FeaturedCards from "../../fragments/FeaturedCards";

import Loader from "../../fragments/Loader";
import DesignCard from "../DesignCard";

export default function Featured() {
  const { designs, loading } = useFetchDesigns();

  return (
    <div>
      <Container className="sub-section">
        <Row>
          <Col md={6} className="mx-auto mb-4 text-center">
            <h2 className="h2-fix mb-2">Featured Designs</h2>
            <hr width="300" className="text-center mx-auto" />
          </Col>
        </Row>
        <Row>
          {loading && <Loader />}
          {designs.slice(0, 6).map((design) => {
            return <DesignCard key={design.id} params={design} />;
          })}
        </Row>
      </Container>
    </div>
  );
}
