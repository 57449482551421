import React, { useState, useEffect } from "react";

//bootstrap import
import { Container, Row, Col } from "react-bootstrap";

import useFetchProjects from "../hooks/useFetchProjects";

import SubAllProjects from "../fragments/SubAllProjects";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // import projects
  const { projects, loading } = useFetchProjects();

  //implementing pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [ProjectsPerPage] = useState(9);

  //get current Projects
  const indexOfLastProject = currentPage * ProjectsPerPage;
  const indexOfFirstProject = indexOfLastProject - ProjectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* BROWSE ProjectS TEXT */}

        <Row>
          <Col md={12} className="text-white text-center purple-header-2">
            <h1 className="h1-fix">Projects</h1>
          </Col>
        </Row>

        {/* Project CARDS */}

        <Container className="mt-4">
          <SubAllProjects projects={projects} loading={loading} />

          <Row className="mt-4 mb-4">
            {/* <Pagination
              paginate={paginate}
              ProjectsPerPage={ProjectsPerPage}
              totalProjects={Projects.length}
            /> */}
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
