import React, { useState, useContext } from "react";

import "react-notifications-component/dist/theme.css";

import Select from "react-select";

//
import { store } from "react-notifications-component";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

//firebase import
import { upload_design } from "../../../firebase";

import { app } from "../../../firebase";

import { DashboardLayout } from "../../fragments/Layout";

import Loader from "../../../fragments/Loader";

import {
  Container,
  Row,
  Button,
  Form,
  Col,
  Card,
  ProgressBar,
} from "react-bootstrap";

// redirects
import { Redirect } from "react-router-dom";
// authentication
import { AuthContext } from "../../contexts/AuthContext";

const Uploaddesign = (props) => {
  //use context to get sign in status
  const { loadinggg, loggedIn } = useContext(AuthContext);

  //uploading file images
  const [filenames, setFilenames] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setIsUploading(true);
    setUploadProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
    //show notification of error
    console.log("error uploading: ", error);
  };

  const handleUplaodSucess = async (filename) => {
    const downloadURL = await app
      .storage()
      .ref("building-designs")
      .child(filename)
      .getDownloadURL();

    setFilenames((prevValues) => {
      return [...prevValues, filename];
    });
    setDownloadURLs((prevValues) => {
      return [...prevValues, downloadURL];
    });
    setUploadProgress(100);
    setIsUploading(false);
  };

  //values being stored for design
  const [buildingType, setBuildingType] = useState(null);
  const [area, setArea] = useState(null);
  const [storey, setStorey] = useState(null);
  const [bedrooms, setBedrooms] = useState(null);
  const [diningroom, setDiningroom] = useState(null);
  const [livingroom, setLivingroom] = useState(null);
  const [washrooms, setWashrooms] = useState(null);
  const [desc, setDesc] = useState(null);
  const [entrances, setEntrances] = useState(null);

  //options for react select
  const buildingTypeOptions = [
    { value: "Residential", label: "Residential" },
    { value: "Apartment", label: "Apartment" },
    { value: "Commercial", label: "Commercial" },
  ];

  //accumulating values to send to firebase
  const values = {
    buildingType,
    storey,
    bedrooms,
    diningroom,
    livingroom,
    washrooms,
    desc,
    entrances,
    area,
    downloadURLs,
    filenames,
  };

  //handle changes
  const handleAreaChange = (e) => {
    setArea(e.target.value);
  };

  const handleStoryChange = (e) => {
    setStorey(e.target.value);
  };

  const handleBedroomsChange = (e) => {
    setBedrooms(e.target.value);
  };

  const handleDescChange = (e) => {
    setDesc(e.target.value);
  };

  const handleDiningRoomChange = (e) => {
    setDiningroom(e.target.value);
  };

  const handleLivingRoomChange = (e) => {
    setLivingroom(e.target.value);
  };

  const handleWashroomsChange = (e) => {
    setWashrooms(e.target.value);
  };

  const handleEntrancesChange = (e) => {
    setEntrances(e.target.value);
  };

  //check all fields and allow upload button
  let allowUpload = true;

  if (
    values.buildingType != null &&
    values.storey != null &&
    values.bedrooms != null &&
    values.diningroom != null &&
    values.livingroom != null &&
    values.washrooms != null &&
    values.desc != null &&
    values.entrances != null &&
    values.area
  ) {
    allowUpload = false;
  }

  // uplaod to firestore
  const handleUpload = () => {
    upload_design(values);

    store.addNotification({
      title: "Uploaded Successfully",
      message: "Building design uploaded successfully",
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 50,
        onScreen: true,
      },
    });

    // redirect
    //props.history.push("/admin/designs");
  };

  // authentication redirect

  if (loadinggg === true && loggedIn === false) {
    return <Loader />;
  }

  if (loadinggg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  return (
    <DashboardLayout>
      <Container className="mt-12 enlarge pb-4">
        <Row>
          <h2 className="ml-3">Upload New Building Design</h2>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Card className="default-card">
              <Card.Body>
                <Row>
                  <Col md={6}>
                    <p className="text-muted">General Features</p>
                    <Form>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Description of building"
                          defaultValue={desc}
                          onChange={handleDescChange}
                          rows={5}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>
                          Ground floor area (in square meters)
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Ground floor area"
                          defaultValue={area}
                          onChange={handleAreaChange}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6}>
                    <p className="text-muted">Numbers and sizes</p>
                    <Form>
                      <Form.Group>
                        <Form.Label>Type of Building Design</Form.Label>
                        <Select
                          placeholder="Type of Building"
                          defaultValue={buildingType}
                          onChange={setBuildingType}
                          options={buildingTypeOptions}
                        />
                      </Form.Group>
                      <Row>
                        <Col md={6}>
                          {" "}
                          <Form.Group>
                            <Form.Label>Storey</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="eg.24"
                              defaultValue={storey}
                              onChange={handleStoryChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Bedrooms</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="eg. 6"
                              defaultValue={bedrooms}
                              onChange={handleBedroomsChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          {" "}
                          <Form.Group>
                            <Form.Label>Dining Rooms</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="eg.24"
                              defaultValue={diningroom}
                              onChange={handleDiningRoomChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Living Rooms</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="eg. 6"
                              defaultValue={livingroom}
                              onChange={handleLivingRoomChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          {" "}
                          <Form.Group>
                            <Form.Label>Washrooms</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Number of washrooms"
                              defaultValue={washrooms}
                              onChange={handleWashroomsChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Entrances</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Number of entrances"
                              defaultValue={entrances}
                              onChange={handleEntrancesChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="text-muted">Gallery Images for Design</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div>
                      <FileUploader
                        accept="image/*"
                        name="image-uploader-multiple"
                        randomizeFilename
                        storageRef={firebase.storage().ref("building-designs")}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={handleUplaodSucess}
                        onProgress={handleProgress}
                        multiple
                      />
                      <Row className="mt-2 mb-4">
                        <Col md={4}>
                          Progress
                          <ProgressBar
                            now={uploadProgress}
                            label={`${uploadProgress}%`}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-4">
                        <Col md={6}>
                          <p>Image Previews</p>{" "}
                        </Col>
                      </Row>
                      <Row>
                        {downloadURLs.map((downloadURL, i) => {
                          return (
                            <Col md={3} className="mr-auto">
                              <img
                                className="upload-preview-image"
                                key={i}
                                src={downloadURL}
                                alt={i}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mx-auto text-center mt-4 mb-4">
                    <Button
                      variant="primary"
                      disabled={allowUpload}
                      onClick={handleUpload}
                    >
                      Upload Design
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </DashboardLayout>
  );
};

export default Uploaddesign;
