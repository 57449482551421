import React from "react";

import { Container, Row, Col, Spinner } from "react-bootstrap";

export default function Loader() {
  return (
    <Container>
      <Row>
        <Col md={6} className="text-center mx-auto my-auto loader-area">
          <Spinner animation="border" />
          <p className="text-muted mt-4">Loading. Please wait</p>
        </Col>
      </Row>
    </Container>
  );
}
