import React, { useState } from "react";

import { DashboardLayout } from "../fragments/Layout";

import FileUploader from "react-firebase-file-uploader";

import { app, deleteSlider, slider_upload } from "../../firebase";

import useFetchSliders from "../../hooks/useFetchSliders";

import firebase from "firebase";

import Loader from "../../fragments/Loader";

// boostrap imports
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Carousel,
  ProgressBar,
} from "react-bootstrap";

export default function Slideshow() {
  const { sliders, loading } = useFetchSliders();
  // file upload
  //uploading file images
  const [filenames, setFilenames] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleUploadStart = () => {
    setIsUploading(true);
    setUploadProgress(0);
  };

  const handleProgress = (progress) => {
    setIsUploading(true);
    setUploadProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
    //show notification of error
    console.log("error uploading: ", error);
  };

  const handleUplaodSucess = async (filename) => {
    const downloadURL = await app
      .storage()
      .ref("sliders")
      .child(filename)
      .getDownloadURL();

    setFilenames((prevValues) => {
      return [...prevValues, filename];
    });
    setDownloadURLs((prevValues) => {
      return [...prevValues, downloadURL];
    });
    setUploadProgress(100);
    setIsUploading(false);

    const data = {
      filename,
      downloadURL,
    };
    slider_upload(data);
  };

  // delete slider

  const onDelete = (data) => {
    deleteSlider(data);
  };

  return (
    <DashboardLayout>
      <Container className="mt-12 pl-4  enlarge">
        {loading && <Loader />}
        <Row>
          <Col md={12}>
            <h3 className="h3-fix mb-4">Current Slideshow</h3>
          </Col>
        </Row>
        <Row>
          <Carousel>
            {sliders.map((slide) => {
              return (
                <Carousel.Item>
                  <img
                    className="d-block w-100 admin-slider"
                    src={slide.downloadURL}
                    alt="First slide"
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Row>

        {/* current images */}
        <Row className="mb-4 mt-4">
          <Col md={12}>
            <h3>Current Slide Images</h3>
          </Col>

          {sliders.map((slide) => {
            return (
              <Col md={3}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={slide.downloadURL}
                    className="admin-card-img"
                  />
                  <Card.Body>
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => onDelete(slide)}
                    >
                      Delete
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>

        {/* upload new images */}
        <Row className="mt-4 mb-4">
          <Col md={12}>
            <h3 className="h3-fix">Upload New Slide Images</h3>
          </Col>
          <Col md={12}>
            <div>
              <FileUploader
                accept="image/*"
                name="image-uploader-multiple"
                randomizeFilename
                storageRef={firebase.storage().ref("sliders")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUplaodSucess}
                onProgress={handleProgress}
              />
              <Row className="mt-2 mb-4">
                <Col md={4}>
                  Progress
                  <ProgressBar
                    now={uploadProgress}
                    label={`${uploadProgress}%`}
                  />
                </Col>
              </Row>
              <Row className="mt-2 mb-4">
                <Col md={6}>
                  <p>Uploaded Image Previews</p>{" "}
                </Col>
              </Row>
              {/* <Row>
                  {downloadURLs.map((downloadURL, i) => {
                    return (
                      <Col md={3} className="mr-auto">
                        <img
                          className="upload-preview-image"
                          key={i}
                          src={downloadURL}
                          alt={i}
                        />
                      </Col>
                    );
                  })}
                </Row> */}
            </div>
          </Col>
        </Row>
      </Container>
    </DashboardLayout>
  );
}
