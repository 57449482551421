import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

export default function Scripture() {
  return (
    <div className="scipture">
      <Container className="sub-section">
        <Row className="padding-extra">
          <Col md={10} className="mx-auto text-center">
            <h3 className="h3-fix">
              "But don't begin until you count the cost. For who would begin
              construction of a building without first calculating the cost to
              see if there is enough money to finish it?"
            </h3>
            <br />
            <h5 className="h3-fix">Luke 14:28</h5>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
