import React, { useEffect } from "react";

//other components import
import Footer from "../components/Footer";

// navbar light
import NavigationBar from "../components/Navbar-light";
import useFetchSingleProject from "../hooks/useFetchSingleProject";

//import Loader
import Loader from "../fragments/Loader";

//boostrap imports
import { Container, Col, Row, Card, Carousel } from "react-bootstrap";

export default function SingleProject(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createdAt = props.match.params.id;

  const { project, loading } = useFetchSingleProject(createdAt);

  //set building tyoe

  let gallery = [];

  if (loading) {
    return <Loader />;
  } else {
    gallery = project.gallery;
  }

  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col
            md={12}
            className="text-white text-center header-image-single-design"
          >
            <Carousel pause={false}>
              {gallery.map((project) => {
                return (
                  <Carousel.Item interval={3000}>
                    <img
                      className="d-block single-design-header-image"
                      src={project}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      {/* <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
        </Row>
        <Container className="mt-4 mb-4 ">
          <Card className="section-card">
            <Row className="mt-4 mb-4">
              <Col md={12} className="text-center">
                <h4 className="h3-fix mb-4">Project Title</h4>
                <p>{project.title}</p>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col md={12} className="text-center">
                <h4 className="h3-fix mb-4">Description</h4>
                <p>{project.desc}</p>
              </Col>
            </Row>
          </Card>
        </Container>

        {/* DETAILS */}
      </Container>
      <Footer />
    </>
  );
}
