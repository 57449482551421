import React, { useEffect } from "react";

import { Link } from "react-router-dom";
//other components import
import Footer from "../components/Footer";

// navbar light
import NavigationBar from "../components/Navbar-light";
import useFetchSingleDesign from "../hooks/useFetchSingleDesign";
import useFetchDesigns from "../hooks/useFetchDesigns";

//import Loader
import Loader from "../fragments/Loader";

//boostrap imports
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  Table,
  Carousel,
} from "react-bootstrap";

import FeaturedCards from "../fragments/FeaturedCards";

// light box
import Light from "../fragments/Light";

export default function SingleDesign(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { designs, loadingg } = useFetchDesigns();

  const createdAt = props.match.params.id;

  const { design, loading } = useFetchSingleDesign(createdAt);

  //set building tyoe

  let gallery = [];

  if (loading) {
    return <Loader />;
  } else {
    gallery = design.gallery;
  }

  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col
            md={12}
            className="text-white text-center header-image-single-design"
          >
            <Carousel pause={false}>
              {gallery.map((design) => {
                return (
                  <Carousel.Item interval={3000}>
                    <img
                      className="d-block single-design-header-image"
                      src={design}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      {/* <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p> */}
                    </Carousel.Caption>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
        </Row>
        <Container className="mt-4 mb-4 ">
          <Card className="section-card">
            <Row className="mt-4 mb-4">
              <Col md={6}>
                <h4 className="h3-fix mb-4">Description</h4>
                <p>{design.desc}</p>
              </Col>
              <Col md={6} className="text-center">
                <Row>
                  <Col md={12}>
                    {" "}
                    <Link
                      to={`/designs/registration/${createdAt}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button variant="primary" className="mt-4">
                        Purchase Design
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* talbe of features */}
            <Row className="mt-4 mb-4">
              <Col md={12}>
                <h4 className="h3-fix mb-4">General Features</h4>
                <Table striped hover>
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td>{design.buildingType}</td>
                    </tr>
                    <tr>
                      <td>Storey</td>
                      <td>{design.storey}</td>
                    </tr>
                    <tr>
                      <td>Bedrooms</td>
                      <td>{design.bedrooms}</td>
                    </tr>
                    <tr>
                      <td>Ground Floor Area</td>
                      <td>{design.area} m²</td>
                    </tr>
                    <tr>
                      <td>Washrooms</td>
                      <td>{design.washrooms}</td>
                    </tr>
                    <tr>
                      <td>Dining Rooms</td>
                      <td>{design.diningroom}</td>
                    </tr>
                    <tr>
                      <td>Living Room</td>
                      <td>{design.livingroom}</td>
                    </tr>
                    <tr>
                      <td>Number of Entrances</td>
                      <td>{design.entrances}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>

            <Row className="mt-4 mb-4">
              <h4 className="h3-fix mb-4">Images / Views of design</h4>

              <Col md={12} className="mt-4">
                <p className="text-muted"> Tap on image to view fullscreen</p>{" "}
                <Row>
                  <Light gallery={gallery} />
                </Row>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col md={12}>
                <h4 className="mb-4 h3-fix">Similar Designs</h4>
                <Row>
                  {loadingg && <Loader />}
                  {designs
                    .filter(
                      (designss) =>
                        designss.buildingType === design.buildingType
                    )
                    .filter(
                      (designss) => designss.createdAt !== design.createdAt
                    )
                    .slice(0, 4)
                    .map((design) => {
                      return (
                        <FeaturedCards key={design.createdAt} params={design} />
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </Card>
        </Container>

        {/* DETAILS */}
      </Container>
      <Footer />
    </>
  );
}
