import React, { useState, useEffect } from "react";
import Select from "react-select";

//import fetch design hook
import useFetchDesigns from "../hooks/useFetchDesigns";

//bootstrap import
import { Container, Row, Col, Button } from "react-bootstrap";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";
import Pagination from "../fragments/Pagination";
import SubAllDesigns from "../fragments/SubAllDesigns";

// Custom Styles for Select Boxes
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted #ccc",
    color: state.isSelected ? "purple" : "black",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    borderWidth: 0,
    cursor: "default",
    display: "-webkit-flex",
    position: "relative",
    outline: 0,
    minHeight: "38px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default function BrowseDesigns() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { designs, loading } = useFetchDesigns();

  const [allDes, setAllDes] = useState(designs);

  //Building Design type
  const [buildingType, setBuildingType] = useState(null);

  //bedroom option
  const [bedroom, setBedroom] = useState(null);

  //washroom options
  const [washroom, setWashroom] = useState(null);

  //square meter options
  const [sqm, setSqm] = useState(null);

  //sort by options

  const buildingTypeOptions = [
    { value: "Residential", label: "Residential" },
    { value: "Apartment", label: "Apartment" },
    { value: "Commercial", label: "Commercial" },
  ];

  const bedroomOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5+" },
  ];

  const washroomOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5+" },
  ];

  const sqmOptions = [
    { value: "50", label: "50m² - 99 m²" },
    { value: "100", label: "100m² - 199m²" },
    { value: "200", label: "200m² - 299m²" },
    { value: "300", label: "More than 300m²" },
  ];

  const sortOption = [{ value: "low", label: "Low to High (Price)" }];

  //FILTERS TO SEND
  let filters = {
    buildingType: buildingType,
    washroom: washroom,
    bedroom: bedroom,
    area: sqm,
  };

  //implementing pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [designsPerPage] = useState(9);

  //get current designs
  const indexOfLastDesign = currentPage * designsPerPage;
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;
  const currentDesigns = allDes.slice(indexOfFirstDesign, indexOfLastDesign);

  // reset button
  const reset = () => {
    setAllDes(designs);
    window.location.reload(false);
  };

  //INITIAL RENDER UPDATES WHEN STATE/SEARCH CRITERIA IS CHANGED

  useEffect(() => {
    let filteredDesigns = designs;

    //FILTER: BUILDING TYPE
    if (buildingType != null) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.buildingType === buildingType.value
      );
    }

    //FILTER: BEDROOM TYPE
    if (bedroom != null && bedroom.value < 5) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.bedrooms === bedroom.value
      );
    }
    //check for more than 5 bedrooms
    if (bedroom != null && bedroom.value > 4) {
      if (bedroom.value > 4) {
        filteredDesigns = filteredDesigns.filter((des) => des.bedrooms >= 5);
      }
    }

    // FILTER: FLOOR AREA
    if (sqm != null && sqm.value >= 50 && sqm.value <= 99) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.area >= 50 && des.area <= 99
      );
    }

    if (sqm != null && sqm.value >= 100 && sqm.value <= 199) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.area >= 100 && des.area <= 199
      );
    }

    if (sqm != null && sqm.value >= 200 && sqm.value <= 299) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.area >= 200 && des.area <= 299
      );
    }
    if (sqm != null && sqm.value >= 300) {
      filteredDesigns = filteredDesigns.filter((des) => des.area >= 300);
    }

    //FILTER: WASHROOMS
    if (washroom != null && washroom.value < 5) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.washrooms === washroom.value
      );
    }

    if (washroom != null && washroom.value > 4) {
      //check for more than 5 bedrooms
      if (washroom.value > 4) {
        filteredDesigns = filteredDesigns.filter((des) => des.washrooms > 5);
      }
    }

    //PACKAGE AND SEND TO RENDER
    setAllDes(filteredDesigns);
  }, [buildingType, washroom, bedroom, designs, sqm]);
  //change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* BROWSE DESIGNS TEXT */}
        {/* <Row>
          <Col md={12} className="text-white text-center browse-purple-header">
            <h3 className="h2-fix">Browse Designs</h3>
          </Col>
        </Row> */}
        <Row>
          <Col md={12} className="text-white text-center purple-header-2">
            <h1 className="h1-fix">Browse Designs</h1>
          </Col>
        </Row>

        {/* FILTER OPTIONS */}
        <Row className="filter-bar pl-5 pr-5 pb-2">
          <Container>
            <Row className="filter-bar-2 mb-2 mt-2">
              {/* BUILDING TYPE */}
              <Col sm={12} md={2} lg={2} className="my-auto">
                {/* TYPE OF BUILDING */}
                <Select
                  styles={customStyles}
                  placeholder="Type of Building"
                  defaultValue={buildingType}
                  onChange={setBuildingType}
                  options={buildingTypeOptions}
                />
              </Col>
              {/* NUMBER OF BEDROOMS */}
              <Col sm={12} md={2} lg={2} className="my-auto">
                <Select
                  styles={customStyles}
                  placeholder="Number of Bedrooms"
                  defaultValue={bedroom}
                  onChange={setBedroom}
                  options={bedroomOptions}
                />
              </Col>
              {/* Floor Areas */}
              <Col sm={12} md={2} lg={2} className="my-auto">
                {/* TYPE OF BUILDING */}
                <Select
                  styles={customStyles}
                  placeholder="Floor Area"
                  defaultValue={sqm}
                  onChange={setSqm}
                  options={sqmOptions}
                />
              </Col>
              {/* Number of Washrooms */}
              <Col sm={12} md={2} lg={2} className="my-auto">
                <Select
                  styles={customStyles}
                  placeholder="Number of Washrooms"
                  defaultValue={washroom}
                  onChange={setWashroom}
                  options={washroomOptions}
                />
              </Col>
              {/* Reset all Filters */}
              <Col sm={12} md={2} className="my-auto">
                <Button size="sm" onClick={reset}>
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </Container>
        </Row>

        {/* DESIGN CARDS */}
        <Container className="mt-4">
          <SubAllDesigns
            filters={filters}
            designs={currentDesigns}
            loading={loading}
          />

          <Row className="mt-4 mb-4">
            <Pagination
              paginate={paginate}
              designsPerPage={designsPerPage}
              totalDesigns={designs.length}
            />
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
