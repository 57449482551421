import React, { useEffect } from "react";

//bootstrap import
import { Container, Row, Col, Card } from "react-bootstrap";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

// Image imports
import home from "../img/commprog.jpg";
import support from "../img/supportedu.jpg";

export default function SocialResponsibilities() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col md={12} className="text-white text-center purple-header">
            <h1 className="h1-fix">Our Social Responsibilities</h1>
          </Col>
        </Row>
        <Container className="mt-4 mb-4 ">
          <Card className="section-card">
            <Container className="inner-container">
              {/* INTRO TEXT */}
              <Row className="mb-4 pb-4">
                <Col md={9} className="mx-auto justify">
                  <p className="text-center p-size">
                    Across all the services we provide, one of our key objective
                    is to enhance people’s lives in the community by ensuring
                    our works also benefit others. We have dedicated this page
                    to share our continued support of the communities and
                    projects we are involved in.
                  </p>
                </Col>
              </Row>

              {/* SOCIAL RESPONSIBILITIES */}
              <Row className="mt-4 pt-4">
                <Col md={6} className="px-4 social-item">
                  <img
                    src={home}
                    className="whatwedo-img"
                    alt="Delivering community projects"
                  />
                  <h3 className="text-center h3-fix">Community Projects</h3>
                  {/* <p>
                    Looking to build your next home? Let’s help you get started.
                    At Plans and Bills, we build homes to fit your taste. From
                    conception to construction and beyond, we are ready to work
                    with you to build your dream home. Visit our gallery of
                    ultramodern designs today.
                  </p> */}
                </Col>
                <Col md={6} className="px-4 social-item">
                  <img
                    src={support}
                    className="whatwedo-img"
                    alt="Supporting Education"
                  />
                  <h3 className="text-center h3-fix">Educational Support</h3>
                  {/* <p>
                    Looking to build your next home? Let’s help you get started.
                    At Plans and Bills, we build homes to fit your taste. From
                    conception to construction and beyond, we are ready to work
                    with you to build your dream home. Visit our gallery of
                    ultramodern designs today.
                  </p> */}
                </Col>
              </Row>
            </Container>
          </Card>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
