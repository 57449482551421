import React from "react";

import { Row, Col } from "react-bootstrap";

//other component import
import ProjectCard from "../fragments/ProjectCards";

import Loader from "../fragments/Loader";

export default function SubAllDesigns({ projects, loading }) {
  if (loading) {
    return <Loader />;
  }

  if (projects.length < 1) {
    return (
      <Row>
        <Col md={6} className="mx-auto  mt-4 text-center">
          <h1>Sorry </h1>
          <p className="text-muted">No results found for selected criteria</p>
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <Row>
        {projects.map((project) => {
          return <ProjectCard key={project.id} params={project} />;
        })}
      </Row>
    </div>
  );
}
