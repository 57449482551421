import React from "react";

import { Row, Col } from "react-bootstrap";

//other component import
import DesignCard from "../components/DesignCard";

import Loader from "../fragments/Loader";

export default function SubAllDesigns({ designs, loading, filters }) {
  if (loading) {
    return <Loader />;
  }

  //display filters in not found message

  //let bedroomfilter = null;
  if (filters.bedroom != null) {
    //console.log("bedroom", filters.bedroom.value);
    // bedroomfilter = filters.bedroom.value;
  }

  if (designs.length < 1) {
    return (
      <Row>
        <Col md={6} className="mx-auto  mt-4 text-center">
          <h1>Sorry </h1>
          <p className="text-muted">No results found for selected criteria</p>
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <Row>
        {designs
          // .filter((design) => event.status !== "closed")
          .map((design) => {
            return <DesignCard key={design.id} params={design} />;
          })}
      </Row>
    </div>
  );
}
