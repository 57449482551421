import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./main.bundle.css";

//
import ReactNotification from "react-notifications-component";

//boostrap
import "bootstrap/dist/css/bootstrap.min.css";

//app import
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
