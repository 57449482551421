import React, { useEffect } from "react";

//bootstrap import
import { Container, Spinner, Card, Row, Col } from "react-bootstrap";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

export default function WhoWeAre() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // team members from firestore
  let loading = false;

  let team = [
    // {
    //   name: "Kwame Okai Ntoni",
    //   role: "Managing Partner",
    //   description: "lorem ipusm dolor si amet",
    //   photo: null,
    // },
    // {
    //   name: "Alicia Zimmermann Ntoni",
    //   role: "Business Development and Finance",
    //   description: "lorem ipusm dolor si amet",
    //   photo: null,
    // },
    // {
    //   name: "Kofi Wilson",
    //   role: "Architect",
    //   description: "lorem ipusm dolor si amet",
    //   photo: null,
    // },
    // {
    //   name: "Amoako Stephen",
    //   role: "PRO",
    //   description: "lorem ipusm dolor si amet",
    //   photo: null,
    // },
    // {
    //   name: "Christopher Asare",
    //   role: "Head of Operations",
    //   description: "lorem ipusm dolor si amet",
    //   photo: null,
    // },
  ];
  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col md={12} className="text-white text-center purple-header">
            <h1 className="h1-fix">Who We Are</h1>
          </Col>
        </Row>
        <Container className="mt-4 mb-4 ">
          <Card className="section-card">
            <Container className="inner-container">
              <Row>
                <Col md={11} className="mx-auto justify">
                  <p className="text-center p-size">
                    We are a leading builder in diverse fields. Plans and Bills
                    has earned recognition for undertaking diverse projects,
                    fostering innovation in project management and making a
                    difference for our clients, employees and community. We are
                    committed to the future by strengthening relationships with
                    existing clients, construction professionals and the
                    community whilst proactively seeking new opportunities.
                  </p>
                </Col>
              </Row>
              {/* SECTION HEADER */}
              <Row className="mt-4 pt-4 pb-4">
                <Col md={12} className="text-center mt-4 mb-4">
                  <h2 className="h2-fix">Meet the Team</h2>
                </Col>
              </Row>

              <Row>
                <Col md={11} className="mx-auto justify">
                  <p className="text-center p-size">
                    We believe that the most integral part of our business is
                    our people. With our extensive experience and clear vision,
                    we drive a culture that inspires creativity and supports
                    innovative industry-leading transformation. Confident,
                    determined and imaginative. These are our shared
                    characteristics that set us apart. Our staff operate with
                    the highest standard of professionalism, integrity and
                    teamwork in all aspects of their day to day work, reflecting
                    our company values.
                  </p>
                </Col>
              </Row>
              {/* TEAM PROFILE */}
              {/* <Row className="mt-4">
                {loading && (
                  <Col md={12} className="text-center mx-auto mt-4">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </Col>
                )}
                {team.map((person) => {
                  return (
                    <Col md={4} className="mt-4">
                      <Card className="team-card">
                        <Card.Img
                          variant="top"
                          src=" https://via.placeholder.com/1100"
                        />
                        <Card.Body>
                          <Card.Title>{person.name}</Card.Title>
                          <p className="text-muted">{person.role}</p>
                          <Card.Text>{person.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </Row> */}
            </Container>
          </Card>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
