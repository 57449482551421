import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

// react booststrap
import { Col } from "react-bootstrap";

// export const photos = [
//   {
//     src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
//     width: 0.2,
//     height: 0.2,
//   },
//   {
//     src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
//     width: 0.2,
//     height: 0.2,
//   },
//   {
//     src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
//     width: 0.2,
//     height: 0.2,
//   },
//   {
//     src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
//     width: 0.2,
//     height: 0.2,
//   },
// ];

export default function Light(props) {
  const gallery = props.gallery;

  let photoss = [];

  // loop through array

  var arrayLength = gallery.length;

  for (var i = 0; i < arrayLength; i++) {
    photoss.push({
      src: gallery[i],
      width: 1,
      height: 1,
    });
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Col>
      <Gallery
        photos={photoss}
        onClick={openLightbox}
        className="custom-gallery"
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photoss.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Col>
  );
}
