import React, { useContext, useState } from "react";

import { Redirect } from "react-router-dom";

// React Boostrap Imports
import { Container, Row, Card, Col, Form, Button } from "react-bootstrap";

//Firebase Imports
import { auth } from "../../firebase";
import { AuthContext } from "../contexts/AuthContext";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //use context to get sign in status
  const { loadinggg, loggedIn } = useContext(AuthContext);

  if (loadinggg === false && loggedIn === true) {
    return <Redirect to="/admin/dashboard" />;
  }

  // Handle Login Process
  const handleLogin = async () => {
    try {
      const credential = auth.signInWithEmailAndPassword(email, password);
      console.log("auth credential", credential);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Container fluid className="login-page text-white">
      <Row>
        <Container>
          <Row className="">
            <Col md={5} className="text-center mx-auto my-auto">
              <div>
                <h1 className="mb-1">Plans and Bills</h1>
                <p className="text-muted">Administrator Log In</p>
                <Form onSubmit={handleLogin} className="mb-4">
                  <Form.Group>
                    <Form.Control
                      className="text-center form-input"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      className="text-center form-input"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Button
                    block
                    variant="primary"
                    className="mt-4"
                    onClick={handleLogin}
                  >
                    Log In
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}
