import React, { useState, useContext } from "react";

import { DashboardLayout } from "../../fragments/Layout";

// redirects
import { Redirect } from "react-router-dom";
// authentication
import { AuthContext } from "../../contexts/AuthContext";

import { Container, Row, Col } from "react-bootstrap";

import SubAllProjects from "../../../fragments/SubAllProjects";

import useFetchProjects from "../../../hooks/useFetchProjects";

const AllProjects = () => {
  //use context to get sign in status
  const { loadingg, loggedIn } = useContext(AuthContext);

  // projects
  // import projects
  const { projects, loading } = useFetchProjects();

  //implementing pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [ProjectsPerPage] = useState(9);

  //get current Projects
  const indexOfLastProject = currentPage * ProjectsPerPage;
  const indexOfFirstProject = indexOfLastProject - ProjectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  // authentication redirect

  if (loadingg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  return (
    <DashboardLayout>
      <Container className="mt-12 enlarge">
        <Row>
          <Col md={12}>
            <h3>Projects</h3>
            <hr />
            <SubAllProjects projects={projects} loading={loading} />
          </Col>
        </Row>
      </Container>
    </DashboardLayout>
  );
};

export default AllProjects;
