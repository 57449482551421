/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import { Navigation } from "react-minimal-side-navigation";
import { useHistory, useLocation } from "react-router-dom";

// firebase
import { logout } from "../../firebase";
//
import Icon from "awesome-react-icons";
import React, { useState } from "react";

import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

export const Sidenav = () => {
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <React.Fragment>
      {/* Sidebar Overlay */}
      <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
          isSidebarOpen ? "block" : "hidden"
        }`}
      />

      <div>
        <button
          className="btn-menu"
          onClick={() => setIsSidebarOpen(true)}
          type="button"
        >
          <Icon name="burger" className="w-6 h-6" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
          isSidebarOpen ? "ease-out translate-x-0" : "ease-in -translate-x-full"
        }`}
      >
        <div className="flex items-center justify-center mt-8 text-center py-6">
          <span className="mx-2 text-2xl font-semibold text-black">
            Plans and Bills
          </span>
        </div>

        <Navigation
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
          }}
          items={[
            {
              title: "Dashboard",
              itemId: "/admin",
              elemBefore: () => <Icon name="coffee" />,
            },
            {
              title: "Slideshow",
              itemId: "/admin/slideshow",
              elemBefore: () => <Icon name="coffee" />,
            },
            {
              title: "Clients",
              itemId: "/admin/clients",
              elemBefore: () => <Icon name="user" />,
            },
            {
              title: "Designs",
              itemId: "#designs",
              elemBefore: () => <Icon name="settings" />,
              subNav: [
                {
                  title: "All Designs",
                  itemId: "/admin/designs",
                },
                {
                  title: "Upload New Design",
                  itemId: "/admin/designs/upload",
                },
              ],
            },
            {
              title: "Projects",
              itemId: "#projects",
              elemBefore: () => <Icon name="coffee" />,
              subNav: [
                {
                  title: "All Projects",
                  itemId: "/admin/projects",
                },
                {
                  title: "Upload New Project",
                  itemId: "/admin/projects/upload",
                },
              ],
            },
            // {
            //   title: "Who We Are",
            //   itemId: "#",
            //   elemBefore: () => <Icon name="users" />,
            // },
            // {
            //   title: "Social Responsibilities",
            //   itemId: "#social",
            //   elemBefore: () => <Icon name="book" />,
            // },
            // {
            //   title: "Log Out",
            //   itemId: "#log-out",

            //   elemBefore: () => <Icon onClick={() => logout()} name="power" />,
            // },
          ]}
        />

        <div className="absolute bottom-0 w-full my-8">
          <Navigation
            activeItemId={location.pathname}
            items={[
              {
                title: "Settings",
                itemId: "/settings",
                elemBefore: () => <Icon name="activity" />,
              },
            ]}
            onSelect={({ itemId }) => {
              history.push(itemId);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidenav;
