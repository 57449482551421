import React, { useContext } from "react";

import dayjs from "dayjs";

// redirects
import { Redirect } from "react-router-dom";
// authentication
import { AuthContext } from "../../contexts/AuthContext";

import { Col, Table, Row } from "react-bootstrap";
import SingleClient from "./SingleClient";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default function SubAllClients({ clients, loading }) {
  //use context to get sign in status
  const { loadingg, loggedIn } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (loadingg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Row>
        <Col md={12}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Registered Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Building Plan ID</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {clients
                // .filter((design) => event.status !== "closed")
                .map((client) => {
                  //registered at date
                  let registerDate = client.registeredAt;

                  var utcSeconds = registerDate;
                  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                  d.setUTCSeconds(utcSeconds);

                  let actDate = dayjs(d).format("LL");

                  return (
                    <SingleClient
                      client={client}
                      actDate={actDate}
                      registerDate={registerDate}
                    />
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
