import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

export default function Ready() {
  return (
    <div className="ready-to">
      <Container className="sub-section">
        <Row className="padding-extra">
          <Col md={10} className="mx-auto text-center">
            <h1 className="h2-fix ready-design">
              We are ready to design and build your dream home today
            </h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6} className="mx-auto text-center">
            <a href="tel:+233277656523">
              <Button variant="light">Call us</Button>
            </a>
            <a href="mailto:info@plansandbills.com">
              <Button variant="light" className="ml-3">
                Mail us
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
