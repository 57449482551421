import React, { useState } from "react";

import {
  Col,
  Container,
  Carousel,
  Table,
  Row,
  Button,
  Modal,
} from "react-bootstrap";

// hook
import useFetchSingleDesign from "../../../hooks/useFetchSingleDesign";

// loading
import Loader from "../../../fragments/Loader";

export default function SingleClient({ client, actDate, registerDate }) {
  const [show, setShow] = useState(false);

  // selected building
  let sb = client.selectedBuilding;

  let id = sb.substring(2);

  const { design, loading } = useFetchSingleDesign(id);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <React.Fragment>
      {/* CLIENT MODAL UI */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{client.name}'s Registration Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h5 className="text-muted">BioData</h5>
                <p> Name: {client.name}</p>
                <p>Email: {client.email}</p>
                <p> Phone: {client.phone}</p>
                <p>Country: {client.country}</p>
                <p>Region: {client.region}</p>
              </Col>
              <Col md={6}>
                <p>Plot of Land: {client.plotOfLand}</p>
                <p>Time to Build: {client.timeToBuild}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5 className="text-muted">Client Additional Notes</h5>
                <p>{client.notes}</p>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <h5 className="text-muted">Building Design Selected</h5>
                <p>ID: {client.selectedBuilding}</p>
              </Col>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <Container>
                  <Row>
                    <Col
                      md={12}
                      className="text-white text-center header-image-single-design"
                    >
                      <Carousel pause={false}>
                        {design.gallery.map((design) => {
                          return (
                            <Carousel.Item interval={3000}>
                              <img
                                className="d-block single-design-header-image"
                                src={design}
                                alt="First slide"
                              />
                              <Carousel.Caption>
                                {/* <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p> */}
                              </Carousel.Caption>
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </Col>
                  </Row>
                  <Container>
                    <Row className="mt-4 mb-4">
                      <Col md={12}>
                        <h4>Description</h4>
                        <p>{design.desc}</p>
                      </Col>
                    </Row>

                    {/* talbe of features */}
                    <Row className="mt-4 mb-4">
                      <Col md={12}>
                        <h4>General Features</h4>
                        <Table striped hover>
                          <tbody>
                            <tr>
                              <td>Type</td>
                              <td>{design.buildingType}</td>
                            </tr>
                            <tr>
                              <td>Storey</td>
                              <td>{design.storey}</td>
                            </tr>
                            <tr>
                              <td>Bedrooms</td>
                              <td>{design.bedrooms}</td>
                            </tr>
                            <tr>
                              <td>Ground Floor Area</td>
                              <td>{design.area} m²</td>
                            </tr>
                            <tr>
                              <td>Washrooms</td>
                              <td>{design.washrooms}</td>
                            </tr>
                            <tr>
                              <td>Dining Rooms</td>
                              <td>{design.diningroom}</td>
                            </tr>
                            <tr>
                              <td>Living Room</td>
                              <td>{design.livingroom}</td>
                            </tr>
                            <tr>
                              <td>Number of Entrances</td>
                              <td>{design.entrances}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              )}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* end of modal */}

      <tr>
        <td>{actDate}</td>
        <td>{client.name}</td>
        <td>{client.email}</td>
        <td>{client.phone}</td>
        <td>{client.selectedBuilding}</td>
        <td>
          {" "}
          <Button variant="info" size="sm" onClick={handleShow}>
            View More
          </Button>
        </td>
      </tr>
    </React.Fragment>
  );
}
