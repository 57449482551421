import React, { useEffect } from "react";

//bootstrap import
import { Container, Card, Row, Col } from "react-bootstrap";

// icons
import { RiTeamLine } from "react-icons/ri";
import { GiFallingStar, GiVibratingShield } from "react-icons/gi";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

export default function HowWeWork() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="min-height-100">
        <NavigationBar />

        {/* HEADER TEXT */}
        <Row>
          <Col md={12} className="text-white text-center purple-header">
            <h1 className="h1-fix">How We Work</h1>
          </Col>
        </Row>
        <Container className="mt-4 mb-4 ">
          <Card className="section-card">
            <Container className="inner-container">
              {/* INTRO TEXT */}
              <Row>
                <Col md={10} className="mx-auto justify">
                  <p className="text-center p-size">
                    We are committed to the future by strengthening
                    relationships with existing clients, construction
                    professionals and the community whilst proactively seeking
                    new opportunities
                  </p>
                </Col>
              </Row>

              {/* OUR VISION STATEMENT */}
              <Row className="mt-4 pt-4">
                <Col md={10} className="text-center mx-auto">
                  <h2 className="h2-fix ">Our Vision</h2>
                  <hr width="300" className="text-center mx-auto mt-2" />
                  <p className="p-size pb-4 mt-4 mb-2">
                    To be the leading construction and real estate services
                    provider recognized for undertaking diverse projects,
                    fostering innovation in project management and making a
                    difference for our clients, employees and community in Ghana
                    and beyond.
                  </p>
                </Col>
              </Row>

              {/* OUR VALUES */}
              <Row className="mt-4 pt-4 mb-4 pb-4">
                <Col md={12} className="text-center">
                  <h2 className="text-center h2-fix">Our Values</h2>
                  <hr width="200" className="text-center mx-auto mt-2" />
                </Col>
              </Row>

              {/* VALUES  */}

              <Row>
                <Col md={4} className="text-center px-3">
                  <RiTeamLine size={80} />
                  <h4 className="mt-2 h3-fix mb-3">Teamwork</h4>
                  <p className="p-size">
                    We work together to drive our complementary strength and
                    skills to accomplish any objective set for every project.
                    Teamwork makes the dream work
                  </p>
                </Col>
                <Col md={4} className="text-center px-3">
                  <GiFallingStar size={80} />
                  <h4 className="mt-2 h3-fix mb-3">Integrity</h4>
                  <p className="p-size">
                    -We believe in transparency and always doing the right thing
                    without any compromise. Integrity is the foundation of your
                    trust and confidence in us.
                  </p>
                </Col>
                <Col md={4} className="text-center px-3">
                  <GiVibratingShield size={80} />
                  <h4 className="mt-2 h3-fix mb-3">Quality</h4>
                  <p className="p-size">
                    We ensure that our team consistently delivers the right
                    services to guarantee we have satisfied and happy customers
                    who can continually count on us.
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
