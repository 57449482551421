import React from "react";

import { Link } from "react-router-dom";
//Importing react bootstrap
import { Card, Col, Row } from "react-bootstrap";

//react icons
import { BiBuildings } from "react-icons/bi";
import { FaRestroom, FaBed } from "react-icons/fa";
import { IoTabletLandscape } from "react-icons/io5";

export default function FeaturedCards(props) {
  const params = props.params;

  //get first gallery image and  use that as card image
  //checking for undefined to avoid errors

  const featured = params.gallery;
  let featuredImage = null;
  if (featured !== undefined) {
    featuredImage = featured[0];
  }

  return (
    <Col md={3} className="mb-4">
      <Link
        to={`/designs/${params.createdAt}`}
        style={{ textDecoration: "none" }}
      >
        <Card className="design-card">
          <Card.Img
            className="design-card-img"
            variant="top"
            alt="Building Design"
            src={featuredImage}
          />
          <Card.Body>
            <Card.Title>
              <h4>{params.buildingType}</h4>
            </Card.Title>
            <Card.Text className="design-card-text">
              <Row>
                <Col>
                  <BiBuildings />{" "}
                  <span className="mr-1"> {params.storey} Storey</span>{" "}
                  <FaRestroom /> <span>{params.washrooms} Washroom</span> <br />
                  <FaBed />{" "}
                  <span className="mr-1">{params.bedrooms} Bedroom</span>{" "}
                  <IoTabletLandscape />
                  <span>{params.area}m² Area</span>
                </Col>
              </Row>
            </Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
}
