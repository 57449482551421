import { useReducer, useEffect } from "react";

//importing firebase and initializing db constant
import { firestore } from "../firebase";

const ACTIONS = {
  MAKE_REQUEST: "make-request",
  GET_DATA: "get-data",
  ERROR: "error",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.MAKE_REQUEST:
      return { loading: true, designs: [] };

    case ACTIONS.GET_DATA:
      return { ...state, loading: false, design: action.payload.design };

    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        designs: [],
      };

    default:
      return state;
  }
}

export default function useFetchSingleDesign(params) {
  const createdAt = parseInt(params);

  const [state, dispatch] = useReducer(reducer, { designs: [], loading: true });

  useEffect(() => {
    //retrieving all the designs
    let alldesigns = [];
    dispatch({ type: ACTIONS.MAKE_REQUEST });
    async function getAlldesigns() {
      const designs = firestore
        .collection("designs")
        .where("createdAt", "==", createdAt);
      // .where("startdate", ">=", params)
      // .orderBy("startdate", "asc");

      const snapshot = await designs.get();
      if (snapshot.empty) {
        console.log("returned empty");
        return;
      }

      snapshot.forEach((doc) => {
        alldesigns.push(doc.data());
        dispatch({
          type: ACTIONS.GET_DATA,
          payload: { design: alldesigns[0] },
        });
      });
    }

    getAlldesigns();
  }, [createdAt]);

  return state;
}
