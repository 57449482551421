import React, { useState, useContext, useEffect } from "react";

import { Row, Col, Form, Button } from "react-bootstrap";

import Loader from "../../../fragments/Loader";

//other component import
import AdminDesignCards from "../../fragments/AdminDesignCards";

// redirects
import { Redirect } from "react-router-dom";
// authentication
import { AuthContext } from "../../contexts/AuthContext";

export default function SubAllDesigns({ designs, loading }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //use context to get sign in status
  const { loadingg, loggedIn } = useContext(AuthContext);

  // store building plan to search
  const [plan, setPlan] = useState(null);
  const [allDes, setAllDes] = useState(designs);

  // all plans
  useEffect(() => {
    let filteredDesigns = designs;

    let searchPlan = parseInt(plan);

    // filter based on
    if (plan != null) {
      filteredDesigns = filteredDesigns.filter(
        (des) => des.createdAt === searchPlan
      );
    }

    setAllDes(filteredDesigns);
  }, [designs, plan]);

  // authentication redirect

  if (loadingg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          <h4>Search for Building Plan</h4>
          <Form>
            <Form.Group>
              <Form.Control
                type="Building Plan ID"
                value={plan}
                onChange={(e) => setPlan(e.target.value)}
                placeholder="Eg. PB1297289792"
              />
            </Form.Group>
          </Form>
        </Col>
        <Col md={6}>
          <Button variant="primary" onClick={(e) => setPlan(null)}>
            Clear Search
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        {allDes.map((design) => {
          return <AdminDesignCards key={design.id} params={design} />;
        })}
      </Row>
    </div>
  );
}
