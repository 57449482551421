import React, { useState } from "react";

// switch and select

import Switch from "react-switch";
import Select from "react-select";

//notifications
import { store } from "react-notifications-component";

// notification
import "react-notifications-component/dist/theme.css";

//
import { delete_design, edit_design, toggle_visibility } from "../../firebase";

//Importing react bootstrap
import {
  Container,
  Card,
  Col,
  Row,
  Modal,
  Button,
  Form,
} from "react-bootstrap";

//react icons
import { BiBuildings } from "react-icons/bi";
import { FaRestroom, FaBed } from "react-icons/fa";
import { BiLandscape } from "react-icons/bi";

export default function AdminDesignCards(props) {
  const params = props.params;

  const [check, setCheck] = useState(params.visible);

  const handleToggle = () => {
    setCheck(!check);
    const data = {
      id: params.id,
      toggle: !check,
    };
    toggle_visibility(data);
  };

  // let visibility = "Visible";
  // if (params.visible === true) {
  //   visibility = "Visible";
  // } else {
  //   visibility = "Not visible";
  // }

  //get first gallery image and  use that as card image
  //checking for undefined to avoid errors

  // modal controls
  const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);

  const handleClose = () => setShow(false);

  const handleEdit = () => {
    // edit details

    edit_design(values);
    setShow(false);

    store.addNotification({
      title: "Edited Successfully",
      message: "Building design edited successfully",
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 50,
        onScreen: true,
      },
    });
  };
  const handleShow = () => setShow(true);

  //
  const handleCloseDel = (id) => {
    // console.log
    delete_design(id);
    setShowDel(false);
    store.addNotification({
      title: "Deleted Building Design",
      message: "Building design deleted permanently",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 100,
        onScreen: true,
      },
    });
  };

  const handleCloseDelete = () => {
    setShowDel(false);
  };
  const handleShowDel = () => setShowDel(true);

  const featured = params.gallery;
  let featuredImage = null;
  if (featured !== undefined) {
    featuredImage = featured[0];
  }

  //values being stored for design
  const [buildingType, setBuildingType] = useState(params.buildingType);
  const [area, setArea] = useState(params.area);
  const [storey, setStorey] = useState(params.storey);
  const [bedrooms, setBedrooms] = useState(params.bedrooms);
  const [diningroom, setDiningroom] = useState(params.diningroom);
  const [livingroom, setLivingroom] = useState(params.livingroom);
  const [washrooms, setWashrooms] = useState(params.washrooms);
  const [desc, setDesc] = useState(params.desc);
  const [entrances, setEntrances] = useState(params.entrances);

  //options for react select
  // const buildingTypeOptions = [
  //   { value: "Residential", label: "Residential" },
  //   { value: "Apartment", label: "Apartment" },
  //   { value: "Commercial", label: "Commercial" },
  // ];

  //accumulating values to send to firebase
  const values = {
    buildingType,
    storey,
    bedrooms,
    diningroom,
    livingroom,
    washrooms,
    desc,
    entrances,
    area,
    filenames: params.filenames,
    id: params.id,
  };

  return (
    <Col md={4} className="mb-4">
      {/* MODAL TO EDIT*/}
      <Modal
        size="lg"
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Editing <strong>{params.buildingType} </strong> Building Design
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={6}>
                <p className="text-muted">General Features</p>
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Description of building"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      rows={5}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      Ground floor area (in square meters)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Ground floor area"
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6}>
                <p className="text-muted">Numbers and sizes</p>
                <Form>
                  <Form.Group disabled>
                    <Form.Label>Type of Building Design</Form.Label>
                    <Select
                      disabled
                      placeholder={params.buildingType}
                      value={buildingType}
                      // options={buildingTypeOptions}
                    />
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      {" "}
                      <Form.Group>
                        <Form.Label>Storey</Form.Label>
                        <Form.Control
                          type="number"
                          value={storey}
                          onChange={(e) => setStorey(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Bedrooms</Form.Label>
                        <Form.Control
                          type="number"
                          value={bedrooms}
                          onChange={(e) => setBedrooms(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {" "}
                      <Form.Group>
                        <Form.Label>Dining Rooms</Form.Label>
                        <Form.Control
                          type="number"
                          value={diningroom}
                          onChange={(e) => setDiningroom(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Living Rooms</Form.Label>
                        <Form.Control
                          type="number"
                          value={livingroom}
                          onChange={(e) => setLivingroom(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      {" "}
                      <Form.Group>
                        <Form.Label>Washrooms</Form.Label>
                        <Form.Control
                          type="number"
                          value={washrooms}
                          onChange={(e) => setWashrooms(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Entrances</Form.Label>
                        <Form.Control
                          type="number"
                          value={entrances}
                          onChange={(e) => setEntrances(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
            <Row className="px-4">
              <h5 className="text-muted">Building Design Images</h5>
            </Row>
            <Row className="px-4 mb-4">
              {params.gallery.map((downloadURL, i) => {
                return (
                  <Col md={3} className="mr-auto">
                    <img
                      className="upload-preview-image"
                      key={i}
                      src={downloadURL}
                      alt={i}
                    />
                  </Col>
                );
              })}
            </Row>

            {/*  */}
            <hr />
            <h6>Deletion cannot be undone</h6>
            <Row className="mt-4 mb-4">
              <Col md={12}>
                <Button
                  variant="danger"
                  size="sm"
                  className="button-small"
                  onClick={handleShowDel}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END OF MODAL */}

      {/* DELETE CONFIRMATION */}
      <Modal show={showDel} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Building Design?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You cannot undo this action. The building design would be deleted
          entirely
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleCloseDel(params.id)}
          >
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END OF DELETE CONFIRMATION */}
      <Card className="design-card">
        <Card.Img
          className="design-card-img"
          variant="top"
          alt="Building Design"
          src={featuredImage}
        />
        <Card.Body>
          <Card.Title>
            <h4>{params.buildingType}</h4>
          </Card.Title>
          <Card.Text className="design-card-text">
            <Row className="mt-2 mb-2">
              <Col>
                <span className="mr-1">
                  {" "}
                  Building ID: <strong> {params.createdAt}</strong>
                </span>{" "}
              </Col>
            </Row>
            <hr />
            <Row className="mt-2 mb-2">
              <Col md={6}>
                <BiBuildings />{" "}
                <span className="mr-1"> {params.storey} Storey</span>{" "}
              </Col>
              <Col md={6}>
                <FaRestroom /> <span>{params.washrooms} Washroom</span> <br />
              </Col>
              <Col md={6}>
                <FaBed />{" "}
                <span className="mr-1">{params.bedrooms} Bedroom</span>{" "}
              </Col>
              <Col md={6}>
                <BiLandscape />
                <span>{params.area}m² Area</span>
              </Col>
            </Row>
            <hr />
            <Row className="mt-2 mb-2">
              <Col md={5}>
                <Button
                  className="button-small"
                  variant="primary"
                  size="sm"
                  onClick={handleShow}
                >
                  View
                </Button>
              </Col>
              <Col md={6} className="text-right pr-4">
                <Row>
                  <Col md={12}>
                    <span>Visibility</span>
                  </Col>
                </Row>

                <Switch
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                  onChange={(e) => handleToggle()}
                  checked={check}
                />
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}
