import React from "react";
import { Link } from "react-router-dom";

//impport bootstrap
import { Container, Row, Col } from "react-bootstrap";

class NotFound extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid className="not-found">
          <Row className="row-full-height">
            <Col md={12} className="text-center mx-auto my-auto">
              <h1 className="header">Sorry!</h1>
              <h5 className="text-muted">Page not found</h5>
              <hr width={200} className="text-center mx-auto" />
              <Link to="/">
                <span className="text-muted">Go back to homepage</span>
              </Link>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default NotFound;
