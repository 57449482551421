import { useReducer, useEffect } from "react";

//importing firebase and initializing db constant
import { firestore } from "../firebase";

const ACTIONS = {
  MAKE_REQUEST: "make-request",
  GET_DATA: "get-data",
  ERROR: "error",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.MAKE_REQUEST:
      return { loading: true, projects: [] };

    case ACTIONS.GET_DATA:
      return { ...state, loading: false, projects: action.payload.projects };

    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: [],
      };

    default:
      return state;
  }
}

export default function useFetchprojects(params) {
  const [state, dispatch] = useReducer(reducer, {
    projects: [],
    loading: true,
  });

  useEffect(() => {
    //retrieving all the projects
    let allprojects = [];
    dispatch({ type: ACTIONS.MAKE_REQUEST });
    async function getAllprojects() {
      const projects = firestore.collection("projects");
      // .where("visible", "==", true);
      // .orderBy("startdate", "asc");

      const snapshot = await projects.get();
      if (snapshot.empty) {
        return;
      }

      snapshot.forEach((doc) => {
        allprojects.push(doc.data());
        dispatch({
          type: ACTIONS.GET_DATA,
          payload: { projects: allprojects },
        });
      });
    }

    getAllprojects();
  }, [params]);

  return state;
}
