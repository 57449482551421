import React from "react";

//Importing React Router
import { BrowserRouter, Switch, Route } from "react-router-dom";

//component and page imports
import Landing from "./views/Landing";

import BrowseDesigns from "./views/BrowseDesigns";
import NotFound from "./views/NotFound";
import SingleDesign from "./components/SingleDesign";
import SingleProject from "./components/SingleProject";
import Privacypolicy from "./views/Privacypolicy";
import Registration from "./views/Registration";
import WhatWeDo from "./views/Whatwedo";
import WhoWeAre from "./views/WhoWeAre";
import SocialResponsibilities from "./views/SocialResponsibilities";
import HowWeWork from "./views/HowWeWork";

// admin components
import Login from "./admin/auth/Login";
import AuthContextProvider from "./admin/contexts/AuthContext";
import DashboardPage from "./admin/pages/DashboardPage";
import Uploaddesign from "./admin/pages/designs/Uploaddesign";
import Clients from "./admin/pages/clients/Clients";
import AllDesigns from "./admin/pages/designs/AllDesigns";
import AllProjects from "./admin/pages/projects/AllProjects";
import UploadProject from "./admin/pages/projects/Uploadproject";
import Projects from "./views/Projects";
import Slideshow from "./admin/pages/Slideshow";

//maintenance page
//import Maintenance from "./views/Maintenance";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AuthContextProvider>
          <Switch>
            {/* GENERAL ROUTES */}
            <Route exact path="/" component={Landing} />
            <Route exact path="/designs" component={BrowseDesigns} />
            <Route exact path="/designs/:id" component={SingleDesign} />
            <Route
              exact
              path="/designs/registration/:id"
              component={Registration}
            />

            <Route exact path="/projects/:id" component={SingleProject} />
            <Route exact path="/privacy-policy" component={Privacypolicy} />
            <Route exact path="/how-we-work" component={HowWeWork} />
            <Route exact path="/who-we-are" component={WhoWeAre} />
            <Route exact path="/what-we-do" component={WhatWeDo} />
            <Route exact path="/projects" component={Projects} />
            <Route
              exact
              path="/our-social-responsibilities"
              component={SocialResponsibilities}
            />

            {/* ADMINISTRATOR ROUTES */}
            <Route exact path="/admin" component={Login} />
            <Route exact path="/admin/dashboard" component={DashboardPage} />
            <Route
              exact
              path="/admin/designs/upload"
              component={Uploaddesign}
            />
            <Route exact path="/admin/designs" component={AllDesigns} />
            <Route exact path="/admin/slideshow" component={Slideshow} />

            <Route exact path="/admin/clients" component={Clients} />
            <Route exact path="/admin/projects" component={AllProjects} />
            <Route
              exact
              path="/admin/projects/upload"
              component={UploadProject}
            />
            {/* AUTHENTICATED ONLY ROUTE */}
            {/* LASTLY, THE BROKEN LINK / PAGE NOT FOUND */}
            <Route path="*" component={NotFound} />
          </Switch>
        </AuthContextProvider>
      </div>
    </BrowserRouter>
  );
}
export default App;
