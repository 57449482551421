import React, { useContext } from "react";

import Clock from "react-live-clock";

import { Link } from "react-router-dom";

import { DashboardLayout } from "../fragments/Layout";

import { Container, Row, Col, Card } from "react-bootstrap";

import useFetchDesigns from "../../hooks/useFetchAllDesigns";
import useFetchClients from "../../hooks/useFetchClients";

import Loader from "../../fragments/Loader";

import { logout } from "../../firebase";

// auth context
import { AuthContext } from "../contexts/AuthContext";
import { Redirect } from "react-router-dom";

// image import
import building from "../../img/building.svg";
import people from "../../img/people.svg";

const DashboardPage = () => {
  //use context to get sign in status

  const { designs, loading } = useFetchDesigns();
  const { clients, loadingg } = useFetchClients();

  //use context to get sign in status
  const { loadinggg, loggedIn } = useContext(AuthContext);

  if (loadinggg === true && loggedIn === false) {
    return <Loader />;
  }

  if (loadinggg === false && loggedIn === false) {
    return <Redirect to="/" />;
  }

  if (loading && loadingg) {
    return (
      <DashboardLayout>
        <Container className="mt-12 enlarge">
          <Loader />
        </Container>
      </DashboardLayout>
    );
  }

  // greetings

  let greeting = "Hello!";
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    greeting = "Good morning!";
  } else if (curHr < 18) {
    greeting = "Good afternoon!";
  } else {
    greeting = "Good evening!";
  }
  return (
    <DashboardLayout>
      <Container className="mt-12 enlarge">
        <Row className="dash-board-main text-white">
          <Col md={12}>
            <h1 className="time-dash">
              <Clock format={"HH:mm:ss"} ticking={true} />
            </h1>
            <h4>{greeting}</h4>
          </Col>

          <Col md={6}>
            <p>
              Don't forget to{" "}
              <Link to="/" className="text-white" onClick={logout}>
                Sign Out
              </Link>
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Card className="text-center design-card">
              <Card.Img className="" variant="top" />
              <Card.Body>
                <Card.Title>
                  <Row>
                    <Col md={6} className="my-auto">
                      <img src={building} className="svg-img" alt="buildings" />
                    </Col>
                    <Col md={6}>
                      <h1 className="analytics-number">{designs.length}</h1>
                      <span className="sub-header">Building Designs</span>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5}>
            <Card className="text-center design-card">
              <Card.Img className="" variant="top" />
              <Card.Body>
                <Card.Title>
                  <Row>
                    <Col md={6} className="my-auto">
                      <img src={people} className="svg-img" alt="people" />
                    </Col>
                    <Col md={6}>
                      <h1 className="analytics-number">{clients.length}</h1>
                      <span className="sub-header">Clients</span>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </DashboardLayout>
  );
};

export default DashboardPage;
