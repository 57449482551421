import React, { useEffect, useState } from "react";

//bootstrap import
import {
  Container,
  Modal,
  Button,
  Row,
  Col,
  Card,
  Form,
  Spinner,
} from "react-bootstrap";

// material ui imports
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";

// icon import
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";

//firebase
import { register } from "../firebase";

import useFetchSingleDesign from "../hooks/useFetchSingleDesign";

//components import
import Footer from "../components/Footer";
import NavigationBar from "../components/Navbar-light";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default function Registration(props) {
  const classes = useStyles();
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // created at form params
  const createdAt = props.match.params.id;

  const { design, loading } = useFetchSingleDesign(createdAt);

  let id = "PB" + props.match.params.id;

  // MODAL CONTROLS
  const [show, setShow] = useState(false);

  const [processing, setProcessing] = useState(false);

  const handlePro = () => setProcessing(false);

  const handleClose = () => {
    setShow(false);
    props.history.push("/designs");
  };
  const handleShow = () => setShow(true);

  //REGISTRATION DETAILS COLLECTED
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [selectedBuilding, setSL] = useState(id);
  const [timeToBuild, setTimeToBuild] = useState("");
  const [plotOfLand, setPlotOfLand] = useState("");
  const [notes, setNotes] = useState("");

  // HANDLE CHANGES TO REGISTRATION FORM
  //handle changes
  const handleNameChagne = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  // TIME TO BUILD

  const handleTimeToBuildChange = (event) => {
    setTimeToBuild(event.target.value);
  };

  // plot of and
  const handlePlotOfLandChange = (event) => {
    setPlotOfLand(event.target.value);
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
    //set building
    setSL(id);
  };

  // VALUES BUNDLE
  const values = {
    name,
    email,
    phone,
    country,
    region,
    selectedBuilding,
    timeToBuild,
    plotOfLand,
    notes,
  };
  console.log("values", values);
  //Upload to Firestore
  const handleUpload = async () => {
    try {
      //open modal and ask them to click to go back to browse plans
      register(values, handlePro);
      setShow(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      <Container fluid className="min-height-100 mb-5">
        <NavigationBar />

        {/* modal */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb-4">
            <Container fluid>
              <Row>
                <Col md={8} className="mx-auto">
                  {processing && (
                    <div>
                      {" "}
                      <Spinner animation="border" />
                      <p>Kindly wait, your details are being processed</p>
                    </div>
                  )}
                  {!processing && (
                    <Row className="mb-4">
                      <Col md={12} className="mx-auto text-center mb-4">
                        <IoCheckmarkDoneCircleOutline
                          size="sm"
                          color="#10FFCB"
                        />
                        <h4>Success!</h4>
                        <p>
                          Your request has been successfully sent, a customer
                          representative would contact you within 48 hours
                        </p>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* HEADER TEXT */}
        <Row>
          <Col
            md={12}
            className="text-white text-center register-purple-header"
          >
            <h2 className="h2-fix">Registration</h2>
          </Col>
        </Row>

        {/* CARD FOR REGISTRATION */}
        <Row>
          <Col md={8} className="mx-auto">
            <Card className="register-card">
              <Container>
                <Row>
                  <Col md={12}>
                    <p className="text-muted">Personal & Contact Details</p>
                    <Form>
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="name"
                          value={name}
                          placeholder="Full Name"
                          onChange={handleNameChagne}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form>
                      <Form.Group>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type="text"
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="Email Address"
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form>
                      <Form.Group>
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                          type="number"
                          value={phone}
                          onChange={handlePhoneChange}
                          placeholder="Phone Number"
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form>
                      <Form.Group>
                        <Form.Label>Country of Residence</Form.Label>
                        <Form.Control
                          type="name"
                          value={country}
                          onChange={handleCountryChange}
                          placeholder="Country of Residence"
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form>
                      <Form.Group>
                        <Form.Label>Region </Form.Label>
                        <Form.Control
                          type="text"
                          value={region}
                          onChange={handleRegionChange}
                          placeholder="If in Ghana, which region?"
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className="text-muted">Building Details</p>
                    <Form>
                      <Form.Group>
                        <Form.Label>Building Design Selected</Form.Label>
                        <Form.Control type="text" disabled value={id} />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                {/* MATERIAL UI IMPLEMENTATIONS */}
                <Row>
                  <Col md={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        When do you want to build?
                      </FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={timeToBuild}
                        onChange={handleTimeToBuildChange}
                      >
                        <FormControlLabel
                          value="Immediately"
                          control={<StyledRadio />}
                          label="Immediately"
                        />
                        <FormControlLabel
                          value="3 months"
                          control={<StyledRadio />}
                          label="3 months"
                        />
                        <FormControlLabel
                          value="6 months"
                          control={<StyledRadio />}
                          label="6 months"
                        />
                        <FormControlLabel
                          value="After a year"
                          control={<StyledRadio />}
                          label="After a year"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                  <Col md={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Do you have a plot of land
                      </FormLabel>
                      <RadioGroup
                        aria-label="plotofland"
                        name="plotofland"
                        value={plotOfLand}
                        onChange={handlePlotOfLandChange}
                      >
                        <FormControlLabel
                          value="Yes, I have a plot of land"
                          control={<StyledRadio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No, I don't have a plot of land"
                          control={<StyledRadio />}
                          label="No"
                        />
                        <FormControlLabel
                          value="I'm in the process of acquiring the land"
                          control={<StyledRadio />}
                          label="In process of acquiring the land"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Col>
                </Row>

                {/* end of building */}
                {/* <Row>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Do you have a plot of land?</Form.Label>
                      <Form.Check
                        inline
                        label="Yes"
                        type="checkbox"
                        onChange={() =>
                          handlePlotOfLandChange("Yes, I have a plot of land")
                        }
                        style={{ display: "block" }}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="checkbox"
                        onChange={() =>
                          handlePlotOfLandChange(
                            "No, I don't have a plot of land"
                          )
                        }
                        style={{ display: "block" }}
                      />
                      <Form.Check
                        inline
                        label="In the process"
                        type="checkbox"
                        onChange={() =>
                          handlePlotOfLandChange(
                            "I'm in the process of acquiring the land"
                          )
                        }
                        style={{ display: "block" }}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Any additional notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={notes}
                        onChange={handleNotesChange}
                        placeholder="Any additional notes"
                        rows={5}
                      />
                    </Form.Group>
                  </Col>{" "}
                </Row>
                <Row className="mt-4 mb-4">
                  <Col md={6} className="mx-auto text-center">
                    <Button variant="light" onClick={handleUpload}>
                      {" "}
                      Purchase Plan
                    </Button>
                  </Col>{" "}
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}
